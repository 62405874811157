import { React, useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useParams } from "react-router-dom";
import './loaderStyles.css'
export default function Rewardstick() {
  const {appid, sitekey, action} = useParams(); 
  console.log("PATH Params ====>>", appid, sitekey, action)
  useEffect(() => {
    if (isAndroid) {
      const url =
        `intent://www.rewardstick.com?appid=${appid}&sitekey=${sitekey}&action=${action}/#Intent;scheme=https;package=com.ecologital.rewardstick_app;end`;

      window.location.replace(url);
    } else if (isIOS) {
      // window.location.href = "rewardstick://product/{BAR Code}/{Org ID}";
      window.location.href = `rewardstick://${appid}/${sitekey}/${action}/`;
      setTimeout(function () {
          window.location.href = "https://apps.apple.com/us/app/rewardstick/id1569027075";
      }, 5000);
      // let appLink = window.location.replace("rewardstick://");

      // if (!appLink) {

      //   setTimeout(() => {
      //     window.location.replace(
      //       "https://apps.apple.com/us/app/rewardstick/id1569027075"
      //     );
      //   }, 300);
      // }
    } else {
      return
    }
  }, [appid, sitekey, action])

  return (
    <div>
      <h2>Rewardstick</h2>
      <div style={{width:'96px', margin:"auto", textAlign:'center'}}>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
      <h4>Openning App</h4>
    </div>
  );
}