import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Rewardstick from "./pages/Rewardstick";
import "./App.css";


export default function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Routes>
        <Route path="/rewardstick/:appid/:sitekey/:action" element={<Rewardstick />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}



