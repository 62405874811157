import * as React from "react";
import { Link } from "react-router-dom";
export default function Home() {
    return (
      <>
        <main>
          <h2>Welcome to the Witmeg Scan!</h2>
        </main>
        <nav>
          <Link to="/rewardstick/4/IXPg2NfG8mXNUvs0MkLH4LsQOvCXhqH5yHxqoOQ1AcqFNRZRUosVpY4Pt1U6xWY6CZSIo6AkKNlW75chl6VFTA==/dS7D9mVDfGwGKVh0MsjF8PamhB5yVyVWeT2rkqFTGDQ=">Rewardstick</Link>
        </nav>
      </>
    );
  }